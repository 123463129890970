import React from "react"
import cx from "classnames"

const Container = ({ id, children, containerClassName, className }) => {
  return (
    <div id={id} className={containerClassName}>
      <div className={cx("container", className)}>{children}</div>
    </div>
  )
}

export default Container
