import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Container from "components/Container"
import Icon from "components/Icon"
import Link from "components/Link"

import styles from "./Header.module.scss"

const TitleAndDescription = ({ data }) => {
  const { title } = data.site.siteMetadata

  return (
    <Container containerClassName={styles.container} className={styles.wrap}>
      <div className={styles.logo}>
        <Icon name="logo" width={40} height={40} />
        <h2>{title}</h2>
      </div>

      <div className={styles.navMenu}>
        <ul>
          <li>
            <Link.UnderlineHover trackActive to="/">
              Home
            </Link.UnderlineHover>
          </li>
          <li>
            <Link.UnderlineHover trackActive partiallyActive to="/blog">
              Blog
            </Link.UnderlineHover>
          </li>
          {/* <li>
            <Link.UnderlineHover trackActive to="/courses">
              Guides
            </Link.UnderlineHover>
          </li>
          <li>
            <Link.UnderlineHover trackActive to="/about">
              About
            </Link.UnderlineHover>
          </li> */}
        </ul>
        <Link as="button" variant="cta" to="/contact-us">
          Get Started
        </Link>
      </div>
    </Container>
  )
}

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => <TitleAndDescription data={data} />}
    />
  )
}

export default Header
