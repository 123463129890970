import React from "react"
import cx from "classnames"

import styles from "./Icon.module.scss"

const Icon = ({ name, className, ...rest }) => {
  let Icon

  try {
    Icon = require(`./assets/${name}.svg`)
  } catch (err) {
    throw new Error(`Couldn't find an icon with name "${name}".`)
  }

  return (
    <i className={cx(styles.icon, className)}>
      <Icon {...rest} />
    </i>
  )
}

export default Icon
