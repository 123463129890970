import React from "react"
import cx from "classnames"
import { Link as BaseLink } from "gatsby"

import styles from "./Link.module.scss"
import buttonStyles from "../Button/Button.module.scss"

const Link = ({
  activeClassName,
  className,
  children,
  title,
  trackActive,
  partiallyActive,
  size = "medium",
  type = "normal",
  as = "link",
  variant = "normal",
  ...rest
}) => (
  <BaseLink
    {...rest}
    activeClassName={cx(
      as === "link" && trackActive ? [styles[`${type}Active`]] : [],
      activeClassName
    )}
    partiallyActive={partiallyActive}
    title={title}
    className={cx(
      as === "link"
        ? [styles.link, styles[size], styles[type]]
        : [buttonStyles.button, buttonStyles[size], buttonStyles[variant]],
      className
    )}
  >
    {children}
  </BaseLink>
)

Link.Underline = ({ type, children, ...rest }) => (
  <Link type="underline" {...rest}>
    {children}
  </Link>
)

Link.UnderlineHover = ({ type, children, ...rest }) => (
  <Link type="underlineHover" {...rest}>
    {children}
  </Link>
)

Link.BorderUnderlineHover = ({ type, children, ...rest }) => (
  <Link type="borderUnderlineHover" {...rest}>
    {children}
  </Link>
)

Link.Inverted = ({ type, children, ...rest }) => (
  <Link type="inverted" {...rest}>
    {children}
  </Link>
)

export default Link
